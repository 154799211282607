import axios from 'axios'
import Environment from './environment'

const api = axios.create({
  baseURL: Environment.BASE_API,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }
})

export default api
