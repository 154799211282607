<template>
    <v-overlay v-model="loading" width="auto" class="align-center justify-center">
        <v-progress-circular
          color="blue"
          indeterminate
          :size="82"
          :width="6"
        />
    </v-overlay>
</template>

<script lang="ts" setup>
import { useLoadingStore } from '@/stores/loading';
import { computed } from 'vue';

const loading = computed(() => useLoadingStore().loading)

</script>