const InputCep = {
    mounted: (el: any) => {
        el.addEventListener('input', (event: any) => handleInput(event))
        el.addEventListener('focusout', (event: any) => handleInput(event))
        el.addEventListener('focusin', (event: any) => handleInput(event))

        const handleInput = (event: any) => event.target.value = validInput(event.target.value)
    }
}

function validInput(value: string) {
    value = value.replace(/\D/g, '')

    value = value.replace(/(\d{5})(\d)/, '$1-$2')

    if (value.length > 9) return value.substring(0, 9)

    return value
}

export default InputCep