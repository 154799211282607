import './assets/style.css'
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import PrimeVue from 'primevue/config'
import * as Sentry from '@sentry/vue'

// VueFire - Firebase
import { VueFire } from 'vuefire'
import { db, firebaseApp } from '@/config/firebase'

//Lottiefiles
import { DotLottieVue } from '@lottiefiles/dotlottie-vue'

//theme
import 'primevue/resources/themes/lara-light-indigo/theme.css'

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { md3 } from 'vuetify/blueprints'

// Mdi icons
import '@mdi/font/css/materialdesignicons.css'

import App from './App.vue'
import router from './router'

// Custom locales
import en from './locales/en_us.locale'
import pt from './locales/pt_br.locale'

// Componets
import BaseSkeletonLoader from '@/components/base/BaseSkeletonLoader.vue'
import BasePrimaryButton from './components/base/BasePrimaryButton.vue'

import VueMathjax from 'vue-mathjax-next'

// Directives
import NotNegativeNumber from './directives/not-negative-number'
import InputCep from './directives/input-cep'
import MaxGrade from './directives/max-grade'
import Environment from './config/environment'
import VGrade from './directives/v-grade'
import TinymceComponent from '@/components/tinymce/TinymceComponent.vue'
import { addDoc, collection } from 'firebase/firestore'

const vuetify = createVuetify({
  blueprint: md3,
  components,
  directives,
  theme: {
    themes: {
      light: {
        colors: {
          primary: '#1976D2',
          blue: '#1C86E3',
          background: '#F4F6F7',
          surface: '#FCFCFC',
          'azul-90': '#D2E7F9',
          'preto-90': '#E6E6E6',
          'preto-100': '#FFF',
          'azul-cinza': '#EAF2F6',
          'azul-50': '#1C86E3',
          'azul-99': '#FAFDFE'
        }
      }
    }
  },
  locale: {
    locale: 'pt',
    fallback: 'en',
    messages: { en, pt },
    rtl: {
      customLocale: true
    }
  }
})

const app = createApp(App)
const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

Sentry.init({
  app,
  dsn: Environment.SENTRY_DSN,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ['localhost', /pruvo\.app/gm, /professor-pruvo-v2/g],
  replaysOnErrorSampleRate: 1.0,
  replaysSessionSampleRate: 0.1
})

app.use(pinia).use(router).use(vuetify).use(PrimeVue).use(VueFire, { firebaseApp }).use(VueMathjax)

// Global Components
app
  .component('BaseSkeletonLoader', BaseSkeletonLoader)
  .component('BasePrimaryButton', BasePrimaryButton)
  .component('TinymceComponent', TinymceComponent)
  .component('LottieFile', DotLottieVue)
app
  .directive('cep', InputCep)
  .directive('max-grade', MaxGrade)
  .directive('not-negative-number', NotNegativeNumber)
  .directive('grade', VGrade)

app.mount('#app')

app.config.errorHandler = async (error, vm, info) => await registerLog(error, info, 'ERROR')

app.config.warnHandler = async (error, vm, info) => await registerLog(error, info, 'WARN')

async function registerLog(error: any, info: string, severity: 'ERROR' | 'WARN') {
  try {
    await addDoc(collection(db, 'loggings'), {
      severity,
      error:
        typeof error === 'string'
          ? error
          : {
              message: error?.message,
              stack: error?.stack
            },
      info,
      created_at: new Date()
    })
  } finally {
    console.log(error)
  }
}
