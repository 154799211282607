
import { defineStore } from "pinia";

export const useConnectionStatusStore = defineStore('connectionStatus', {
    state: () => ({
        isUserConnected: true
    }),
    actions: {
        setOnline() {
            this.isUserConnected = true
        },
        setOffline() {
            this.isUserConnected = false
        }
    },
    getters: {
        getIsUserNotConnected(): boolean {
            return !this.isUserConnected
        }
    }
})