import api from '@/config/axios'
import { db } from '@/config/firebase'
import type { Organization } from '@/models/organization.model'
import collect from 'collect.js'
import {
  collection,
  collectionGroup,
  deleteDoc,
  doc,
  DocumentReference,
  getCountFromServer,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where
} from 'firebase/firestore'
import { clearActiveOrganizationOnUser, setActiveOrganizationOnUser } from './user.service'
import { useRouter } from 'vue-router'
import { isNull, isUndefined } from 'lodash'
import { getAuth, getIdToken, type User } from 'firebase/auth'
import type { AxiosRequestConfig } from 'axios'

export const getOrganizationsFromArrayOfRefs = async (
  organizationsRef: DocumentReference[]
): Promise<Organization[]> => {
  const data = await Promise.all(
    collect(organizationsRef)
      .map((organizationRef: DocumentReference) => organizationRef?.id)
      .filter((id) => !isNull(id) && !isUndefined(id))
      .map(async (id: string) => (await getDoc(doc(db, 'organizations', id)))?.data())
      .collapse()
      .all()
  )

  return sortByName(data as Organization[])
}

const sortByName = (arr: Array<Organization>) => {
  return arr.sort((a, b) => a?.name?.trade?.localeCompare(b?.name?.trade))
}

export const setActiveOrganization = async (organizationId: string) => {
  const router = useRouter()
  if (!(await isOrganizationExists(organizationId))) {
    localStorage.clear()
    await clearActiveOrganizationOnUser()
    return router.push({ name: 'organizations' })
  }

  localStorage.setItem('activeOrganization', organizationId)
  await setActiveOrganizationOnUser(organizationId)
}

const isOrganizationExists = async (organizationId: string) => {
  try {
    return (await getDoc(doc(db, 'organizations', organizationId))).exists()
  } catch {
    return false
  }
}

export const getActiveOrganizationData = async (): Promise<Organization> => {
  const activeOrganizationId = localStorage.getItem('activeOrganization')
  if (!activeOrganizationId) {
    throw new Error('No active organization')
  }
  return (await getDoc(doc(db, 'organizations', activeOrganizationId))).data() as Organization
}

export const countPeopleByLevelFromActiveOrganization = async (level: string) => {
  const ref = query(
    collectionGroup(db, 'people'),
    where('level', '==', level),
    where('organization_id', '==', localStorage.getItem('activeOrganization') as string)
  )

  const snapshot = await getCountFromServer(ref)

  return snapshot.data().count
}

export const countAdminsByLevelsFromActiveOrganization = async (levels: Array<string>) => {
  const ref = query(
    collectionGroup(db, 'people'),
    where('level', 'in', levels),
    where('organization_id', '==', localStorage.getItem('activeOrganization') as string)
  )

  const snapshot = await getCountFromServer(ref)

  return snapshot.data().count
}

export const countFormsFromActiveOrganization = async () => {
  const coll = collection(
    db,
    'organizations',
    localStorage.getItem('activeOrganization') as string,
    'forms'
  )
  const snapshot = await getCountFromServer(coll)

  return snapshot.data().count
}

export const getOrganizationAcls = async () => {
  const organizationId = localStorage.getItem('activeOrganization')
  if (!organizationId) {
    throw new Error('No active organization')
  }
  const collectionRef = collection(db, 'organizations', organizationId, 'acls')
  const snapshot = await getDocs(collectionRef)
  const acls = snapshot.docs.map((doc) => doc.data())
  return acls as any[]
}

export const updateGoogleAuthUser = async (user_id: string) => {
  const organization_ref = doc(
    db,
    'organizations',
    localStorage.getItem('activeOrganization') as string
  )
  const user_ref = doc(db, 'users', user_id)

  await updateDoc(organization_ref, {
    gcred_oauth: {
      user_id,
      user_ref
    }
  })

  const classroomCoursesRef = await getDocs(
    query(collection(organization_ref, 'classroom_courses'))
  )

  for (const course of classroomCoursesRef.docs) {
    await deleteDoc(doc(organization_ref, 'classroom_courses', course.id))
  }
}

export const changeOrgAuth = async () => {
  const user = getAuth().currentUser as User
  const token = await getIdToken(user, true)
  return await api.post(
    '/changeOrgClassroomAuthorization',
    {
      organization_id: localStorage.getItem('activeOrganization'),
      email: user.email
    },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    } as AxiosRequestConfig
  )
}

export const updateOrganizationName = async (name: string) => {
  const organization_ref = doc(
    db,
    'organizations',
    localStorage.getItem('activeOrganization') as string
  )

  await updateDoc(organization_ref, {
    'name.trade': name
  })
}

export const updateOrganizationIsShareClassroomActivies = async (
  is_share_activities_on_classroom: boolean
) => {
  const organization_ref = doc(
    db,
    'organizations',
    localStorage.getItem('activeOrganization') as string
  )

  await updateDoc(organization_ref, {
    is_share_activities_on_classroom
  })
}

export const updateOrganizationIsSyncPruvoClassroom = async (is_sync_pruvo_classroom: boolean) => {
  const organization_ref = doc(
    db,
    'organizations',
    localStorage.getItem('activeOrganization') as string
  )

  await updateDoc(organization_ref, {
    is_sync_pruvo_classroom
  })
}

export const updateOrganizationIsApplyToGoogleForms = async (is_apply_to_google_forms: boolean) => {
  const organization_ref = doc(
    db,
    'organizations',
    localStorage.getItem('activeOrganization') as string
  )

  await updateDoc(organization_ref, {
    is_apply_to_google_forms
  })
}

export const updateOrganizationLogo = async (logo: string): Promise<string> => {
  const organization_ref = doc(
    db,
    'organizations',
    localStorage.getItem('activeOrganization') as string
  )

  await updateDoc(organization_ref, {
    logo: logo
  })

  return logo
}
