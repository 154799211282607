<template>
  <v-snackbar v-model="alert.show" :color="color">
    <template #default>
      <div v-for="message, index of alert.messages" :key="index">
        {{ message }}
      </div>
    </template>
    <template v-slot:actions>
      <v-btn color="white" variant="text" @click="useSnackbarStore().hideSnackbar()">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useSnackbarStore } from '@/stores/alert';

const alert = computed(() => useSnackbarStore().snackbar)
const color = computed(() => useSnackbarStore().getColor)
</script>