import { ApplicationStatusEnum } from '@/enums/application-status.enum'
import { auth, db } from '@/config/firebase'
import collect from 'collect.js'
import {
  collection,
  collectionGroup,
  CollectionReference,
  doc,
  DocumentReference,
  getCountFromServer,
  getDoc,
  getDocs,
  increment,
  limit,
  orderBy,
  query,
  QueryFieldFilterConstraint,
  serverTimestamp,
  updateDoc,
  where
} from 'firebase/firestore'
import { getOrganizationsFromArrayOfRefs } from './organization.service'
import type { Organization } from '@/models/organization.model'
import type { PermissionType } from '@/types/permission.type'
import BaseService from './base.service'
import { useUserStore } from '@/stores/user'
import Level from '@/enums/level.enum'

export const getUserOrganizations = async (): Promise<Organization[]> => {
  const user = await getUserData()
  if (!user) {
    return []
  }
  const organizationsRef = await getUserOrganizationsRef(user)
  return await getOrganizationsFromArrayOfRefs(collect(organizationsRef).unique('id').all())
}

export const getUserData = async () => {
  const user = await useUserStore().getAuthUser
  const q = query(collection(db, 'users'), where('email', '==', user?.email), limit(1))
  const querySnapshot = await getDocs(q)
  return querySnapshot?.docs?.[0]
}

export const getUserDataById = async (id: string) => {
  const userRef = doc(db, 'users', id)
  const docSnap = await getDoc(userRef)
  return docSnap.exists() ? docSnap.data() : null
}

const getUserOrganizationsRef = async (user: any): Promise<Array<DocumentReference>> => {
  const { is_super_admin } = user.data()

  if (is_super_admin) {
    const q = query(
      collection(db, 'organizations'),
      where('status', '==', 'ACTIVE'),
      orderBy('name', 'asc')
    )

    const querySnapshot = await getDocs(q)

    return querySnapshot.docs.map((doc) => doc.ref)
  }

  const peopleRef = collectionGroup(db, 'people')
  const q = query(
    peopleRef,
    where('user_ref', '==', doc(db, 'users', user?.id)),
    where('level', '>', '1'),
    orderBy('level', 'desc')
  )
  const querySnapshot = await getDocs(q)
  return collect(querySnapshot.docs.map((doc) => doc.data()))
    .pluck('organization_ref')
    .all() as DocumentReference[]
}

export const setActiveOrganizationOnUser = async (organizationId: string) => {
  const user = await getUserData()
  await updateDoc(user.ref, {
    active_organization: {
      id: organizationId,
      updated_at: serverTimestamp()
    }
  })
}

export const clearActiveOrganizationOnUser = async () => {
  const user = await getUserData()
  await updateDoc(user.ref, {
    active_organization: null
  })
}

export default class UserService extends BaseService {
  constructor() {
    super()
  }

  public async isAllowed(permission: string): Promise<PermissionType | boolean> {
    const email = useUserStore().authUserData?.email

    const userQuery = query(collection(db, 'users'), where('email', '==', email))
    const userQuerySnapshot = await getDocs(userQuery)

    if (!this.organizationId) {
      localStorage.setItem(
        'activeOrganization',
        userQuerySnapshot?.docs?.[0]?.data()?.active_organization?.id
      )
      location.reload()
    }

    if (userQuerySnapshot.empty) {
      return false
    }

    const organizationRef = doc(db, 'organizations', this.organizationId as string)

    const personQuery = query(
      collectionGroup(db, 'people'),
      where('user_ref', '==', userQuerySnapshot.docs[0].ref),
      where('organization_ref', '==', organizationRef),
      limit(1),
      orderBy('level', 'desc')
    )
    const personQuerySnapshot = await getDocs(personQuery)
    if (personQuerySnapshot.empty) {
      return false
    }

    const person = personQuerySnapshot.docs[0].data()
    const aclQuery = doc(organizationRef, 'acls', person.level)
    const aclSnapshot = await getDoc(aclQuery)
    if (!aclSnapshot.exists()) {
      return false
    }

    const acl = aclSnapshot.data()
    const permissionData = collect(acl?.permissions)
      ?.where('name', permission)
      ?.first(undefined, null)
    if (permissionData) {
      return permissionData as PermissionType
    }

    return false
  }

  public async getSchoolsOrCoursesFromUser(field: string, schoolId?: string): Promise<string[]> {
    const user = await getUserData()

    if (user.data()?.is_super_admin === true) {
      const collectionName = field === 'school_id' ? 'schools' : 'courses'
      const q = query(
        collectionGroup(db, collectionName),
        where('organization_id', '==', this.organizationId as string)
      )
      const querySnapshot = (await getDocs(q)).docs.map((doc) => doc.data())
      const items = collect(querySnapshot).pluck('id').all()
      return items as string[]
    }

    const q = query(
      collectionGroup(db, 'people'),
      where('organization_id', '==', this.organizationId as string),
      where('user_id', '==', user?.id),
      ...(schoolId ? [where('school_id', '==', schoolId)] : []),
      where('level', '>', '1')
    )
    const querySnapshot = (await getDocs(q)).docs.map((doc) => doc.data())
    const items = collect(querySnapshot)
      .pluck(field)
      .unique()
      .all()
      .filter((i) => i)
    return items as string[]
  }

  public async getUsersById(ids: string[]): Promise<any[]> {
    const users = await Promise.all(
      collect(ids)
        .chunk(10)
        .map(async (chunk: any) => {
          const q = query(collection(db, 'users'), where('id', 'in', chunk.all()))

          const querySnapshot = await getDocs(q)

          return querySnapshot.docs.map((doc) => doc.data())
        })
        .collapse()
        .all()
    )
    return users?.flat(Infinity)
  }

  public async getApplicationsIdFromUser(userId: string, schoolId: string, courseId: string) {
    const organizationApplicationRef = collection(
      db,
      'organizations',
      this.organizationId as string,
      'applications'
    )

    const [
      organizationApplicationQuery,
      userApplicationsQuery,
      schoolApplicationsQuery,
      courseApplicationsQuery
    ] = [
      this.getOrganizationQuery(organizationApplicationRef, [], true),
      this.getOrganizationQuery(organizationApplicationRef, [
        where('users_id', 'array-contains-any', [userId])
      ]),
      this.getOrganizationQuery(organizationApplicationRef, [
        where('schools_id', 'array-contains-any', [schoolId])
      ]),
      this.getOrganizationQuery(organizationApplicationRef, [
        where('courses_id', 'array-contains-any', [courseId])
      ])
    ]

    const [
      organizationApplicationsData,
      userApplicationsData,
      schoolApplicationsData,
      courseApplicationsData
    ] = await Promise.all([
      getDocs(organizationApplicationQuery),
      getDocs(userApplicationsQuery),
      getDocs(schoolApplicationsQuery),
      getDocs(courseApplicationsQuery)
    ])

    const applications = collect([
      ...organizationApplicationsData.docs.map((doc) => doc?.id),
      ...userApplicationsData.docs.map((doc) => doc?.id),
      ...schoolApplicationsData.docs.map((doc) => doc?.id),
      ...courseApplicationsData.docs.map((doc) => doc?.id)
    ])
      .unique()
      .all()

    return applications as string[]
  }

  public async countApplicationsFromUsers() {
    const q = query(
      collectionGroup(db, 'applications'),
      where('organization_id', '==', localStorage.getItem('activeOrganization') as string),
      where('status', 'in', [ApplicationStatusEnum.PUBLISHED, ApplicationStatusEnum.ENDED]),
      where('user_id', '!=', null)
    )

    const snapshot = await getCountFromServer(q)

    return snapshot.data().count
  }

  public async getUserLevels() {
    const user = await getUserData()
    const q = query(
      collectionGroup(db, 'people'),
      where('user_id', '==', user?.id),
      where('organization_id', '==', this.organizationId as string),
      where('level', '>', '1')
    )
    const querySnapshot = (await getDocs(q)).docs.map((doc) => {
      return {
        level: doc?.data()?.level,
        course_id: doc?.data()?.course_id,
        school_id: doc?.data()?.school_id
      }
    })

    return querySnapshot as []
  }

  public async updateUser(userData: any) {
    return await this.call('/updateUser', userData)
  }

  public async updateUserGeneratedAiQuestion() {
    const user = await getUserData()
    const q = doc(db, 'users', user?.id)
    await updateDoc(q, {
      generated_ai_question_quantity: increment(1)
    })
  }

  public async updateUserLogo(logo: string) {
    const user = await getUserData()

    await updateDoc(user.ref, {
      avatar: logo
    })
  }

  public async getUserMaxLevel(schoolId?: string) {
    const user = await getUserData()
    const q = query(
      collectionGroup(db, 'people'),
      where('user_id', '==', user?.id),
      where('organization_id', '==', this.organizationId as string),
      ...(schoolId ? [where('school_id', '==', schoolId)] : []),
      orderBy('level', 'desc'),
      limit(1)
    )

    const querySnapshot = await getDocs(q)
    return querySnapshot.docs?.[0]?.data()?.level
  }

  public async getSingleUserById() {
    const user = await getUserData()
    const q = query(collection(db, 'users'), where('id', '==', user?.id), limit(1))
    const querySnapshot = await getDocs(q)
    return querySnapshot.docs?.[0]?.data()
  }

  public async getSchoolsIdFromUserId(userId: string) {
    const q = query(
      collectionGroup(db, 'people'),
      where('organization_id', '==', this.organizationId),
      where('user_id', '==', userId),
      where('level', '==', Level.STUDENT)
    )

    const querySnapshot = await getDocs(q)

    return querySnapshot.docs?.map((doc) => doc?.get('school_id'))
  }

  private getOrganizationQuery(
    ref: CollectionReference,
    conditional: QueryFieldFilterConstraint[],
    isApplyToOrganization = false
  ) {
    return query(
      ref,
      where('is_apply_to_organization', '==', isApplyToOrganization),
      ...conditional
    )
  }
}
