import type { DirectiveBinding } from "vue";

const MaxGrade = {
    mounted: (el: any, binding: DirectiveBinding<string>) => {
        const maxValue = Number(binding.value);

        el.addEventListener('input', (event: any) => handleInput(event))
        el.addEventListener('focusout', (event: any) => handleInput(event))
        el.addEventListener('focusin', (event: any) => handleInput(event))

        const handleInput = (event: any) => event.target.value = validInput(event.target.value, maxValue)
    }
}

function validInput(value: string, maxValue: number): string {
    value = replaceComma(value)

    if (isNotNumber(value)) {
        return ''
    }
    
    if (isNotValid(Number(value), maxValue)) {
        return ''
    }

    return value
}

function replaceComma(value: string): string {
    return value.replace(/,/g, '.')
}

function isNotNumber(value: string): boolean {
    return isNaN(Number(value))
}

function isNotValid(value: number, maxValue: number): boolean {
    return value > maxValue || value < 0
}

export default MaxGrade