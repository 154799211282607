const VGrade = {
    mounted: (el: any) => {

        el.addEventListener('input', (event: any) => handleInput(event))
        el.addEventListener('focusout', (event: any) => handleInput(event))
        el.addEventListener('focusin', (event: any) => handleInput(event))

        const handleInput = (event: any) => event.target.value = validInput(event.target.value)
    }
}

function validInput (value: any) {
    value = replaceComma(value)

    if (isNotNumber(value)) {
        return ''
    }

    return value
}

function replaceComma (value: any) {
    return value.replace(/,/g, '.')
}

function isNotNumber (value: any) {
    return isNaN(value)
}

export default VGrade