const NotNegativeNumber = {
  mounted(el: any) {
    el.addEventListener('input', (event: any) => handleInput(event))
    el.addEventListener('focusout', (event: any) => handleInput(event))
    el.addEventListener('focusin', (event: any) => handleInput(event))

    const handleInput = (event: any) => (event.target.value = Math.abs(event.target.value))
  }
}

export default NotNegativeNumber
