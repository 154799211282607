export enum ApplicationStatusEnum {
  'ENDED' = 'ENDED',
  'OPENED' = 'OPENED',
  'PUBLISHED' = 'PUBLISHED',
  'RUNNING' = 'RUNNING',
  'NOT_STARTED' = 'NOT_STARTED',
  'IN_PROGRESS' = 'IN_PROGRESS',
  'CORRECTED' = 'CORRECTED',
  'CANCELLED' = 'CANCELLED',
  'WAITING' = 'WAITING'
}

export const ApplicationStatusLabel = new Map<string, string>([
  [ApplicationStatusEnum.ENDED, 'Concluída'],
  [ApplicationStatusEnum.OPENED, 'Em aberto'],
  [ApplicationStatusEnum.PUBLISHED, 'Nota publicada'],
  [ApplicationStatusEnum.RUNNING, 'Em andamento'],
  [ApplicationStatusEnum.NOT_STARTED, 'Não iniciada'],
  [ApplicationStatusEnum.IN_PROGRESS, 'Em andamento'],
  [ApplicationStatusEnum.CANCELLED, 'Cancelada'],
  [ApplicationStatusEnum.WAITING, 'Aguardando correção']
])
