import { getFunctions } from 'firebase/functions'
import { initializeApp } from 'firebase/app'
import { getGenerativeModel, getVertexAI } from 'firebase/vertexai-preview'
import {
  CACHE_SIZE_UNLIMITED,
  initializeFirestore,
  memoryLocalCache,
  memoryLruGarbageCollector
} from 'firebase/firestore'
import { getStorage } from 'firebase/storage'
import Environment from './environment'
import { getAuth } from 'firebase/auth'

// Inicializar Firebase
export const firebaseApp = initializeApp(Environment.FIREBASE_CONFIG)
export const auth = getAuth(firebaseApp)
export const db = initializeFirestore(firebaseApp, {
  localCache: memoryLocalCache({
    garbageCollector: memoryLruGarbageCollector({
      cacheSizeBytes: CACHE_SIZE_UNLIMITED
    })
  }),
  ignoreUndefinedProperties: true
})
export const functions = getFunctions(firebaseApp, 'southamerica-east1')
export const storage = getStorage(firebaseApp)
export const vertexAI = getVertexAI(firebaseApp)
export const model = getGenerativeModel(vertexAI, { model: 'gemini-1.5-flash' })
