import { auth } from '@/config/firebase'
import type { User } from 'firebase/auth'
import { defineStore } from 'pinia'

export const useUserStore = defineStore('user', {
  persist: true,
  state: () => ({
    authUserData: null as User | null
  }),
  getters: {
    getAuthUser: async (state) => {
      return (
        state.authUserData ??
        (await new Promise<User | null>((resolve) => {
          auth.onAuthStateChanged((user) => {
            state.authUserData = user
            if (user) {
              resolve(user)
            } else {
              resolve(null)
            }
          })
        }))
      )
    }
  }
})
