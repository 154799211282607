import StorageService from '@/services/storage.service'
import { getDownloadURL } from 'firebase/storage'

const storageService = new StorageService()
export async function imagesUploadHandler(blobInfo: any) {
  const { blob } = blobInfo

  if (!blob()) throw new Error('No file')

  const snapshot = await storageService.uploadFile(
    `questions/${new Date().getTime()}-${blobInfo.filename()}`,
    blob()
  )

  return await getDownloadURL(snapshot.ref)
}
