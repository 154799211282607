enum Level {
  STUDENT = '1',
  TEACHER = '10',
  COORDINATOR = '20',
  PRINCIPAL = '30',
  TECHNICAL = '40',
  FINANCIAL = '50',
  PEDAGOGICAL_COORDINATOR = '60',
  ADMINISTRATOR = '70'
}

export default Level
