import { defineStore } from 'pinia'

export const useSidebarStore = defineStore('sidebar', {
  state: () => ({
    collapsable: '1'
  }),
  actions: {
    showCollapsable(show = '1') {
      this.collapsable = show
    }
  },
  getters: {
    isCollapsable(): boolean {
      return this.collapsable === '1'
    }
  }
})
